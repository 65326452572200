h1.page-title {
  color: #14cec5;
  font-weight: bold;
  font-size: 53px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 40px;
  }
  span {
    color: #ea3e66;
  }
}
.network-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .ai-control {
    width: calc(50% - 40px);
    @media (max-width: 767px) {
      width: 100%;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  .ai-swap {
    width: 55px;
    height: 55px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px 0;
    background-color: transparent;
    font-size: 32px;
    color: #fff;
    border: 0;
    padding: 0;
    border-radius: 10px;
    transition: all ease-in-out 0.3s;
    @media (max-width: 767px) {
      height: 40px;
      margin: 0;
    }
    &:hover,
    &:focus {
      background-color: rgba(38, 40, 52, 0.3137254901960784);
      color: rgba(0, 214, 252, 1);
    }
  }
}

.ai-inputx {
  width: 100%;
  background-color: rgb(36 41 66 / 60%);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid transparent;
  color: #fff;
  transition: all 0.25s ease;
  outline: none;
  backdrop-filter: blur(10px);
  padding: 14px 16px;
  height: 55px;
  transition: all ease-in-out 0.3s;
  &:focus {
    border-color: rgba(0, 214, 252, 0.8);
    box-shadow: 0px 1px 8px #00d6fc6c;
  }
  &:not(:disabled) {
    &:hover {
      border-color: rgba(0, 214, 252, 0.8);
      box-shadow: 0px 1px 8px #00d6fc6c;
    }
  }
}
.ai-form-note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  font-size: 12px;
  opacity: 0.7;
}
.ai-input-icon {
  position: relative;
  .ai-inputx {
    padding-left: 50px;
    &.invalid {
      border-color: rgba(255, 193, 7, 0.8) !important;
      box-shadow: 0px 1px 8px #ffc10769;
    }
  }
  img,
  .mdi {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 2;
  }
  .mdi {
    font-size: 20px;
    color: #fff;
  }
}
.ai-input-wrap {
  position: relative;

  .ai-inputx {
    padding-right: 80px;
    font-size: 20px;
    font-weight: 500;
  }
  .buttonx {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    background: linear-gradient(91.16deg, #ea3e66 0.46%, #d23eea 100%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 60px;
    border-radius: 6px;
    transition: all ease-in-out 0.3s;
    &:hover {
      background: linear-gradient(91.16deg, #d44764 0.46%, #d44764 100%);
      color: #fff;
    }
  }
}
.ai-control {
  margin-bottom: 24px;
  label {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
  }
  .ai-input {
    text-decoration: none;
    cursor: pointer;
    position: relative;
    background-color: rgb(36 41 66 / 60%);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid transparent;
    color: hsla(0, 0%, 100%, 0.8);
    font-size: 15px;
    white-space: nowrap;
    font-weight: 400;
    transition: all 0.25s ease;
    outline: none;
    backdrop-filter: blur(10px);
    padding: 14px 16px;
    height: 55px;
    transition: all ease-in-out 0.3s;
    &:hover {
      border-color: rgba(0, 214, 252, 0.8);
      box-shadow: 0px 1px 8px #00d6fc6c;
    }
    .buttonx {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }
}
.ai-network-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  .ai-connected {
    position: absolute;
    bottom: 0;
    right: -8px;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #00aa2b;
    box-shadow: 0px -2px 3px rgb(12 147 46 / 50%);
    margin-left: 4px;
  }
}
.ai-warning {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fffa0036;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  box-shadow: 0 0 5px #ffea00;
  border: 1px solid #ffea00;
  padding: 0.75rem;
  color: #fff;
  opacity: 0.7;
  margin-bottom: 24px;
  transform-origin: center center;
  transform: scale(0.95);
  transition: all ease-in-out 0.3s;
  &:hover {
    opacity: 1;
    transform: scale(1);
  }
}

.ai-error {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f13d3d4d;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  box-shadow: 0 0 5px #f13d3d;
  border: 1px solid #f13d3d;
  padding: 0.75rem;
  color: #fff;
  opacity: 0.7;
  margin-bottom: 24px;
  transform-origin: center center;
  transform: scale(0.95);
  transition: all ease-in-out 0.3s;
  &:hover {
    opacity: 1;
    transform: scale(1);
  }
}
.ai-success {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #00aa2b49;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
  box-shadow: 0 0 5px #00aa2b;
  border: 1px solid #00aa2b;
  padding: 0.75rem;
  color: #fff;
  opacity: 0.7;
  margin-bottom: 24px;
  transform-origin: center center;
  transform: scale(0.95);
  transition: all ease-in-out 0.3s;
  &:hover {
    opacity: 1;
    transform: scale(1);
  }
}
.ai-modal {
  .ai-warning,
  .ai-error,
  .ai-success {
    flex-direction: column;
    .mdi {
      font-size: 40px;
    }
  }
}
.ai-step-trigger {
  background-color: transparent;
  border: 0;
  color: #f6f6f652;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 18px;
  @media (max-width: 991px) {
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
    padding: 6px 8px;
  }
  &.completed {
    color: #fff;
    font-weight: 500;
  }
  &.current {
    color: #fff;
    font-weight: 500;
  }
}

.lds-ripple,
.lds-ripple div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}
