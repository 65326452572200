.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 40px;
}
main {
  min-height: calc(100vh - 260px);
}

.ai-page {
  padding-top: 50px;

  .ai-page-title {
    font-weight: bold !important;
    text-align: center;
    margin-bottom: 0;
    font-size: 40px;
    line-height: 40px;
  }

  .ai-page-inner {
    padding-top: 12px;
  }
}

.ai-modal {
  &.success {
    .modal-content {
      background-color: rgba(17, 219, 171, 0.21);
      background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
      box-shadow: 0 0 5px #11dbab;
      border: 1px solid #11dbab;
    }
  }
  .modal-content {
    background: linear-gradient(90deg, #7237b3 0%, #1f54ae 100%);
    border-radius: 24px;
    color: #fff;
    box-shadow: 1px 2px 50px #7355b052;

    .modal-header {
      border-color: rgba(255, 255, 255, 0.1);
      padding: 20px 30px;
      .modal-title {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        line-height: 1;
      }
      .btn-close {
        background: transparent
          url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
          center/1em auto no-repeat;
      }
    }

    .modal-body {
      padding: 30px;
    }
  }

  @media (min-width: 576px) {
    .modal-sm {
      --bs-modal-width: 360px;
    }
  }
}
.modal-backdrop {
  --bs-backdrop-bg: #00001e;
  --bs-backdrop-opacity: 0.9;
}
//
body {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #00001e;
  overflow-x: hidden;
  position: relative;
  padding-top: 105px;
}

body.no-bg {
  background-image: none !important;
}

body::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

a {
  color: #fff;
  transition: ease 0.3s all;
}

a:hover {
  color: #d44765;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.btn {
  outline: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

a:disabled,
button:disabled {
  user-select: none;
  opacity: 0.4;
}
